html,
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  color: white;
  background-color: #a59ecc;
}

a {
  color: white;
  &:hover {
    color: #aad;
  }
}

.home {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url("../imgs/background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  background-size: 0 0;
  &.fade-in {
    background-size: cover;
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
  }
}

.main-card {
  text-align: center;
  width: 50%;
  flex-grow: 1;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &.fade-in {
    display: flex;
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.card-text {
  padding-top: 20px;
  font-size: 26px;

  @media only screen and (max-width: 900px) {
    font-size: 22px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 18px;
  }
}

.profile-img {
  height: 150px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}

.footer {
  display: none;
  &.fade-in {
    display: unset;
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
  }
  flex: 0 0 80px;
  font-size: 32px;

  @media only screen and (max-width: 900px) {
    flex: 0 0 75px;
    font-size: 26px;
  }

  @media only screen and (max-width: 600px) {
    flex: 0 0 70px;
    font-size: 20px;
  }
  
  a {
    color: white;
    &:hover {
      color: #aad;
    }
  
    &:not(:last-child) {
      padding-right: 20px;
    }
    &:last-child {
      text-decoration: none;
      border: 1px solid white;
      border-radius: 12px;
      padding: 4px;
      &:hover {
        border: 1px solid #aad;
      }
    }
  }
}

.drop {
  position: relative;
  width: 20px;
  height: 20px;
  top: -30px;
  margin: 0 auto;
  background: #fff;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -moz-animation-name: drip;
  -webkit-animation-name: drip;
  animation-name: drip;
  -moz-animation-timing-function: cubic-bezier(1, 0, 0.91, 0.19);
  -webkit-animation-timing-function: cubic-bezier(1, 0, 0.91, 0.19);
  animation-timing-function: cubic-bezier(1, 0, 0.91, 0.19);
  -moz-animation-duration: 2s;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -moz-animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.drop:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 30px solid rgba(255, 255, 255, 1);
  top: -22px;
}

.wave {
  position: relative;
  opacity: 0;
  top: 300px;
  width: 2px;
  height: 1px;
  border: #fff 7px solid;
  -moz-border-radius: 300px / 150px;
  -webkit-border-radius: 300px / 150px;
  border-radius: 300px / 150px;
  -moz-animation-name: ripple;
  -webkit-animation-name: ripple;
  animation-name: ripple;
  -moz-animation-delay: 2s;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -moz-animation-duration: 2s;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -moz-animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.wave:after {
  content: "";
  position: absolute;
  opacity: 0;
  top: -5px;
  left: -5px;
  width: 2px;
  height: 1px;
  border: #fff 5px solid;
  -moz-border-radius: 300px / 150px;
  -webkit-border-radius: 300px / 150px;
  border-radius: 300px / 150px;
  -moz-animation-name: ripple-2;
  -webkit-animation-name: ripple-2;
  animation-name: ripple-2;
  -moz-animation-duration: 2s;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@keyframes ripple {
  from {
    opacity: 1;
  }
  to {
    width: 600px;
    height: 300px;
    border-width: 1px;
    top: 200px;
    opacity: 0;
  }
}

@keyframes ripple-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    width: 200px;
    height: 100px;
    border-width: 1px;
    top: 100px;
    left: 200px;
  }
}

@keyframes drip {
  to {
    top: 300px;
  }
}
